import { FocusTarget, FocusDirection } from './types'

export const getFocusableElements = () => {
  const selector = 'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
  return Array.from(document.querySelectorAll(selector))
}

export const findFocusableElement = (navigation: HTMLElement, direction: FocusDirection): HTMLElement | undefined => {
  const allFocusable = getFocusableElements()
  const navigationIndex = allFocusable.findIndex((el) => navigation.contains(el))

  return direction === 'next'
    ? (allFocusable.find((el, index) => index > navigationIndex && !navigation.contains(el)) as HTMLElement)
    : (allFocusable.findLast((el, index) => index < navigationIndex && !navigation.contains(el)) as HTMLElement)
}

export const focusElement = ({ element, shouldCloseSubmenu = false }: FocusTarget, closeCallback?: () => void) => {
  if (shouldCloseSubmenu && closeCallback) {
    closeCallback()
  }
  element.focus()
}
