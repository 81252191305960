import { findFocusableElement, focusElement } from './focus'

export const getMainMenuItems = () => Array.from(document.querySelectorAll('.menu-item .link'))

export const getActiveMainMenuItem = () => document.querySelector('.menu-item .link.is-expanded') as HTMLElement

export const getSubmenuLinks = (submenu: Element) => Array.from(submenu.querySelectorAll('a'))

export const handleMainMenuKeydown = (
  event: KeyboardEvent,
  menuItem: HTMLElement,
  {
    onItemClick,
    closeSubmenus,
  }: {
    onItemClick: (id: string) => void
    closeSubmenus: () => void
  },
) => {
  const { key, shiftKey } = event

  if (key === 'Enter' || key === ' ') {
    event.preventDefault()
    const itemId = menuItem.getAttribute('data-item-id')
    if (itemId) onItemClick(itemId)
    return
  }

  if (key === 'Tab' && menuItem.getAttribute('aria-expanded') === 'true') {
    event.preventDefault()

    if (!shiftKey) {
      // Tab: Focus first submenu link
      const submenuId = menuItem.getAttribute('aria-controls')
      if (!submenuId) return
      const submenu = document.querySelector(`[data-submenu="${submenuId}"]`)
      const firstLink = submenu?.querySelector('a') as HTMLElement
      if (firstLink) focusElement({ element: firstLink }, closeSubmenus)
    } else {
      // Shift+Tab: Focus previous main item or page element
      const mainItems = getMainMenuItems()
      const currentIndex = mainItems.indexOf(menuItem)
      const prevMainItem = mainItems[currentIndex - 1] as HTMLElement

      if (prevMainItem) {
        focusElement({ element: prevMainItem, shouldCloseSubmenu: true }, closeSubmenus)
      } else {
        const navigation = event.currentTarget as HTMLElement
        const prevElement = findFocusableElement(navigation, 'previous')
        if (prevElement) focusElement({ element: prevElement, shouldCloseSubmenu: true }, closeSubmenus)
      }
    }
  }
}

export const handleSubmenuKeydown = (
  event: KeyboardEvent,
  submenuItem: HTMLAnchorElement,
  submenu: Element,
  closeSubmenus: () => void,
) => {
  const { key, shiftKey } = event
  if (key !== 'Tab') return

  const menuItems = getSubmenuLinks(submenu)
  const currentIndex = menuItems.indexOf(submenuItem)
  const isFirst = currentIndex === 0
  const isLast = currentIndex === menuItems.length - 1

  if (shiftKey && isFirst) {
    // Shift+Tab on first submenu item: Return to main menu
    event.preventDefault()
    const mainMenuItem = getActiveMainMenuItem()
    if (mainMenuItem) focusElement({ element: mainMenuItem, shouldCloseSubmenu: true }, closeSubmenus)
  } else if (!shiftKey && isLast) {
    // Tab on last submenu item: Next main item or page element
    event.preventDefault()
    const currentMainItem = getActiveMainMenuItem()
    if (currentMainItem) {
      const mainItems = getMainMenuItems()
      const currentMainIndex = mainItems.indexOf(currentMainItem)
      const nextMainItem = mainItems[currentMainIndex + 1] as HTMLElement

      if (nextMainItem) {
        focusElement({ element: nextMainItem, shouldCloseSubmenu: true }, closeSubmenus)
      } else {
        const navigation = event.currentTarget as HTMLElement
        const nextElement = findFocusableElement(navigation, 'next')
        if (nextElement) focusElement({ element: nextElement, shouldCloseSubmenu: true }, closeSubmenus)
      }
    }
  }
}

export const handleEscapeKey = (event: KeyboardEvent, activeId: string | null, closeSubmenus: () => void) => {
  if (event.key === 'Escape' && activeId) {
    event.preventDefault()
    const mainMenuItem = getActiveMainMenuItem()
    if (mainMenuItem) focusElement({ element: mainMenuItem, shouldCloseSubmenu: true }, closeSubmenus)
  }
}
