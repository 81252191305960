<script lang="ts" setup>
import { useMainMenu } from './useMainMenu'
import { useSubmenuVisibility } from './useSubmenuVisibility'
import { handleMainMenuKeydown, handleSubmenuKeydown, handleEscapeKey } from '~/lib/a11y'

const { menu } = await useMainMenu()
const { activeId, onClick, closeSubMenus } = useSubmenuVisibility()

// Main keyboard handler
const onKeyDown = (event: KeyboardEvent) => {
  const target = event.target as HTMLElement
  const isMainMenuItem = target.closest('[role="menubar"] a')
  const isSubMenuItem = target.closest('[role="menu"] a')

  if (isMainMenuItem) {
    handleMainMenuKeydown(event, isMainMenuItem as HTMLElement, {
      onItemClick: onClick,
      closeSubmenus: closeSubMenus,
    })
  } else if (isSubMenuItem) {
    const submenu = isSubMenuItem.closest('[data-submenu]')
    if (submenu) handleSubmenuKeydown(event, isSubMenuItem as HTMLAnchorElement, submenu, closeSubMenus)
  }

  // Handle escape key regardless of target
  handleEscapeKey(event, activeId.value, closeSubMenus)
}
</script>

<template>
  <nav
    id="main-navigation"
    tabindex="-1"
    class="navigation"
    aria-label="Main navigation"
    @mouseleave="closeSubMenus"
    @keydown="onKeyDown"
  >
    <MainMenu :menu="menu" :active-id="activeId" @click="onClick" />
    <SubMenus :menu="menu" :active-id="activeId" />
  </nav>
</template>

<style lang="scss" scoped>
.navigation:focus-visible {
  outline: var(--focus-outline);
  outline-offset: 2px;
  border-radius: 3px;
}
</style>
